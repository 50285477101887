<template>
  <svg viewBox="0 0 600 600" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2754_18639)">
      <path d="M600 -0.5H0V600H600V-0.5Z" fill="#C7F6C9" />
      <path
        d="M-30.2227 586.611L168.833 361.5L168.388 286.056L176.5 288.167L182.333 317.444L186.055 323.222L188.333 326L231.555 273.944L240 280.611L198.333 332.833L200.333 334.5L248.222 275.056L258.5 283L213 338.389L214.388 340.389L254.111 291.833L263.611 299.333L223.277 349.222L224.833 350.278L259.333 307.778L268.833 316L234.111 360.833L25.8329 630L-30.2227 586.611Z"
        fill="#D0BFAE"
      />
      <path
        d="M-205.111 385.722L46.6109 221.556L66.222 148.722L73.4998 152.944L71.3331 182.667L73.3887 189.222L74.8331 192.5L130.333 153.833L136.666 162.5L82.6109 201.778L84.1109 203.889L146.111 159.278L153.889 169.722L95.3331 211L96.1665 213.333L147.333 177.056L154.5 186.778L102.389 224.167L103.555 225.611L148.111 193.778L155.055 204.222L109.722 238.278L-162.556 442.444L-205.111 385.722Z"
        fill="#F9C66C"
      />
      <path
        d="M-128.945 476.722L76.7213 257.611L78.4991 182.167L86.5547 184.556L91.4991 213.944L95.0547 219.833L97.2769 222.667L141.999 171.944L150.221 178.833L107.055 229.833L108.999 231.556L158.61 173.5L168.666 181.778L121.555 235.833L122.888 237.833L163.999 190.5L173.277 198.222L131.499 246.889L132.999 248L168.777 206.556L177.999 215.056L141.999 258.889L-74.1675 521.778L-128.945 476.722Z"
        fill="white"
      />
      <path
        d="M79.7227 659.944L207.556 387.944L186 315.667L194.334 315.444L208.112 341.889L213.334 346.389L216.334 348.389L243.223 286.333L253.112 290.333L227.834 352.167L230.223 353.222L259.445 282.667L271.556 287.444L243.445 353.389L245.389 354.889L269.834 297.167L281.056 301.667L256.334 360.889L258.167 361.444L279.334 310.944L290.723 316.222L270 369L145.723 685.833L79.7227 659.944Z"
        fill="white"
      />
      <path
        d="M678.334 624.611L479.278 399.389L479.778 323.944L471.667 326.056L465.834 355.333L462.112 361.111L459.834 363.889L416.612 311.833L408.223 318.5L449.834 370.722L447.834 372.389L400.001 312.889L389.667 320.889L435.167 376.278L433.778 378.278L394.056 329.722L384.556 337.167L424.89 387.056L423.334 388.167L388.834 345.611L379.334 353.833L414.056 398.722L622.223 667.945L678.334 624.611Z"
        fill="#676768"
      />
      <path
        d="M587.334 687.056L443.389 423.222L460.667 349.778L452.278 350.056L440.111 377.278L435.167 382.056L432.334 384.278L401.778 323.889L392.111 328.5L421.056 388.722L418.722 389.889L385.334 321.222L373.5 326.722L405.5 390.889L403.722 392.5L375.834 336.333L364.889 341.5L393.111 399.111L391.334 399.833L367.167 350.667L356.111 356.556L379.945 408.056L523 716.833L587.334 687.056Z"
        fill="#404041"
      />
      <path
        d="M826.721 380.556L577.721 212.333L559.277 139.167L551.944 143.222L553.666 173L551.499 179.556L549.944 182.833L495.11 143.222L488.61 151.778L541.999 191.944L540.499 194.056L479.277 148.444L471.277 158.722L529.221 201L528.332 203.278L477.777 166.167L470.444 175.778L521.944 214L520.721 215.444L476.666 182.889L469.555 193.222L514.388 228L783.277 436.556L826.721 380.556Z"
        fill="white"
      />
      <path
        d="M735.722 480.333L544.611 248.389L547.666 173.056L539.5 174.889L532.666 203.889L528.722 209.556L526.388 212.222L485 158.722L476.333 165.056L516.166 218.722L514.111 220.333L468.333 159.222L457.777 166.833L501.333 223.778L499.833 225.722L461.833 175.833L452.111 182.944L490.666 234.222L489.055 235.222L456.055 191.5L446.277 199.444L479.444 245.444L678.166 521.778L735.722 480.333Z"
        fill="#D0BFAE"
      />
      <path
        d="M187.346 246.345L183.203 249.878L198.777 268.14L202.919 264.607L187.346 246.345Z"
        fill="white"
      />
      <path
        d="M224.877 232.163L219.523 233.154L223.894 256.754L229.247 255.762L224.877 232.163Z"
        fill="white"
      />
      <path
        d="M261.422 230.606L256.031 229.846L252.681 253.611L258.072 254.371L261.422 230.606Z"
        fill="white"
      />
      <path
        d="M299.321 245.246L294.75 242.288L281.711 262.438L286.282 265.396L299.321 245.246Z"
        fill="white"
      />
      <path
        d="M328.976 272.306L325.777 267.901L306.359 282.003L309.558 286.408L328.976 272.306Z"
        fill="white"
      />
      <path
        d="M96.939 112.941L91.7461 114.577L98.9557 137.468L104.149 135.833L96.939 112.941Z"
        fill="white"
      />
      <path
        d="M136.978 114.529L131.662 113.352L126.473 136.783L131.789 137.96L136.978 114.529Z"
        fill="white"
      />
      <path
        d="M171.231 127.386L166.564 124.582L154.202 145.152L158.868 147.957L171.231 127.386Z"
        fill="white"
      />
      <path
        d="M200.395 155.668L197.342 151.161L177.472 164.62L180.525 169.127L200.395 155.668Z"
        fill="white"
      />
      <path
        d="M217.138 192.165L215.912 186.861L192.529 192.263L193.754 197.568L217.138 192.165Z"
        fill="white"
      />
      <path
        d="M330.829 313.671L328.223 318.45L349.292 329.941L351.899 325.162L330.829 313.671Z"
        fill="white"
      />
      <path
        d="M360.841 287.016L356.195 289.855L368.709 310.334L373.355 307.495L360.841 287.016Z"
        fill="white"
      />
      <path
        d="M394.4 272.471L389.094 273.687L394.455 297.08L399.762 295.864L394.4 272.471Z"
        fill="white"
      />
      <path
        d="M435.033 272.578L429.707 271.449L424.729 294.925L430.055 296.055L435.033 272.578Z"
        fill="white"
      />
      <path
        d="M472.359 287.32L467.797 284.348L454.698 304.458L459.26 307.43L472.359 287.32Z"
        fill="white"
      />
      <path
        d="M417.072 168.346L415.836 173.649L439.209 179.099L440.446 173.797L417.072 168.346Z"
        fill="white"
      />
      <path
        d="M438.931 134.696L435.211 138.671L452.736 155.068L456.455 151.093L438.931 134.696Z"
        fill="white"
      />
      <path
        d="M467.315 111.732L462.525 114.32L473.935 135.433L478.724 132.844L467.315 111.732Z"
        fill="white"
      />
      <path
        d="M506.581 101.018L501.146 101.351L502.614 125.306L508.048 124.973L506.581 101.018Z"
        fill="white"
      />
      <path
        d="M546.508 105.196L541.318 103.55L534.063 126.427L539.253 128.072L546.508 105.196Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2754_18639">
        <rect width="100%" height="600" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
