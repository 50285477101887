<template>
  <hr />
</template>

<style lang="scss" scoped>
hr {
  width: 100%;
  margin: 1rem 0;
  border: 0;
  border-top: 1px solid var(--pkt-color-border-gray);
}
</style>
